import React from "react";
import { useParams, Redirect } from "react-router-dom";
import NavBar from "../Layout/NavBar";

const Result = () => {
    const params = useParams();
    const onDashBoard = () => {
        window.location.replace("/dashboard");
    };
    const handleLogoClick = () => {
        window.location.replace("/");
    };
    const handleLogout = () => {
        sessionStorage.setItem("quizoid-isLoggedIn", "");
        sessionStorage.setItem("quizoid-authToken", "");
        window.location.reload();
    };
    const checkLogin = () => {
        return sessionStorage.getItem("quizoid-isLoggedIn") === "LOGGED_IN"
    };
    const loginStatus =
        sessionStorage.getItem("quizoid-isLoggedIn") === "LOGGED_IN"
            ? "LOGGED_IN"
            : "NOT_LOGGED_IN";

    if (!(sessionStorage.getItem("quizoid-isLoggedIn") === "LOGGED_IN")) {
        return <Redirect to={{ pathname: "/login" }} />;
    } else {
        const user_id = sessionStorage.getItem("quizoid-user-id");
        const authToken = sessionStorage.getItem("quizoid-authToken");
        const spreadsheet = new window.dhx.Spreadsheet("spreadsheet", {
            menu: true, // the menu is switched on, false - to switch it off
            // readonly: true,
            // multiSheets: false,
            toolbarBlocks: [
                "undo",
                "colors",
                "decoration",
                "align",
                "lock",
                // "clear",
                // "rows",
                // "columns",
                "format",
                "actions",
                "helpers"
                // "file"
            ],
        });
        document.querySelector(".Quizoid").classList.remove("Quizoid");
        const footer = document.querySelector("footer").outerHTML;
        document.querySelector("footer").outerHTML = '';
        fetch(`/api/v1/quizzes/results/${params.id}`, {
            method: 'POST', // or 'PUT'
            headers: {
                'Content-Type': 'application/json',
                'user_id': user_id,
                'auth-token': authToken,
                'user-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        })
            .then((response) => response.json())
            .then((dataset) => {
                // document.body.innerHTML += footer;
                spreadsheet.parse(dataset);
                for (let i = 0; i < dataset.sheets[0].mergeCells.length; i++) {
                    spreadsheet.mergeCells(dataset.sheets[0].mergeCells[i])
                };
            });
    }
    return (
        <>
            <NavBar
                isLoggedIn={loginStatus}
                checkLogin={checkLogin}
                onDashBoard={onDashBoard}
                onLogout={handleLogout}
                onLogoClick={handleLogoClick}
            />
            <link rel="stylesheet" href="https://cdn.mrayush.me/quizoid/results.css"></link>
            <link rel="stylesheet" href="https://cdn.mrayush.me/quizoid/spreadsheet.css"></link>
            <div className="dhx_sample-container__widget" id="spreadsheet"></div>
        </>
    );
}

export default Result;