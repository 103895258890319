import React from "react";
import Logo from "./Logo";
import NavBarAuth from "./NavBarAuth";
import { Link } from "react-router-dom";

const NavBar = (props) => {
  let logo = null;
  if (props.onLogoClick) {
    logo = (
      <div onClick={props.onLogoClick} style={{ cursor: "pointer" }} className="navbar-brand ml-5 custom-css-7-M">
        <Logo />
      </div>
    );
  } else {
    logo = (
      <Link to="/">
        <div className="navbar-brand ml-5 custom-css-7-M">
          <Logo />
        </div>
      </Link>
    );
  }
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-sm bg custom-css-6-M custom-css-7-M">
        {logo}
        <NavBarAuth onDashBoard={props.onDashBoard} checkLogin={props.checkLogin} onLogout={props.onLogout} />
      </nav>
    </React.Fragment>
  );
};

export default NavBar;
